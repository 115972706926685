import React, { Component, Fragment } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { getServicer, updateServicer } from '../api/serviceavenger'
import parse from 'html-react-parser'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class ExistingAppointment extends Component {
  state = {
    errorMessage: '',
    isNewDateValid: null,
    isNewDateValidated: false,
    isNewTimeValid: null,
    isNewTimeValidated: false,
    isServicerValid: null,
    isServicerValidated: false,
    isServicerVerified: false,
    isServicerLookupWorking: false,
    isSystemIdValid: null,
    isSystemIdValidated: false,
    newDate: moment(new Date()).format('MM/DD/YYYY'),
    newTime: '',
    servicerAddress1: '',
    servicerAddress2: '',
    servicerCity: '',
    servicerState: '',
    servicerCountry: '',
    servicerPostalCode: '',
    servicerName: '',
    servicerNumber: '',
    systemId: '',
    showNewAppointmentModal: false,
  }

  formatPhoneNumber = (string) => {
    let cleaned = ('' + string).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return string
  }

  handleModalReset = () => {
    this.handleValidationReset()
    this.setState({
      isServicerVerified: false,
      newDate: moment(new Date()).format('MM/DD/YYYY'),
      newTime: '',
      servicerAddress1: '',
      servicerAddress2: '',
      servicerCity: '',
      servicerName: '',
      servicerNumber: '',
      servicerPostalCode: '',
      servicerState: '',
      servicerCountry: '',
      systemId: '',
    })
  }

  handleValidationReset = () => {
    this.setState({
      errorMessage: '',
      isNewDateValid: null,
      isNewDateValidated: false,
      isNewTimeValid: null,
      isNewTimeValidated: false,
      isServicerValid: null,
      isServicerValidated: false,
      isSystemIdValid: null,
      isSystemIdValidated: false,
    })
  }

  handleNewDateSelect(date) {
    this.setState({ newDate: moment(date).format('MM/DD/YYYY') })
  }

  handleUpdateServicer = async () => {
    if (this.validateModal()) {
      this.updateServicer()
    }
  }

  validateModal = () => {
    const { newDate, newTime, systemId } = this.state
    this.handleValidationReset()
    let errorMessage = ''

    this.setState({
      errorMessage,
      isNewDateValid: newDate?.length > 0 ? true : false,
      isNewDateValidated: true,
      isNewTimeValid: newTime?.length > 0 ? true : false,
      isNewTimeValidated: true,
      isSystemIdValid: systemId?.length > 0 ? true : false,
      isSystemIdValidated: true,
    })

    return newDate?.length > 0 && newTime?.length > 0 && systemId?.length > 0
  }

  getAppointmentDetails = () => {
    const {
      existingAppointment,
      setRescheduleDialogParent,
      setReassignDialogParent,
      setCancelDialogParent,
    } = this.props

    if (existingAppointment != null) {
      const { appointmentDate, appointmentTimeSlot, servicerName, appointmentDay } =
        existingAppointment

      return (
        <React.Fragment>
          <p>
            <b>
              Servicer Name: {servicerName}
              <br />
            </b>
            <b>
              Servicer Id: {existingAppointment?.servicerAccountId}
              <br />
            </b>
            <b>
              Appointment Date: {moment.utc(appointmentDate).format('MM/DD/YYYY')},{appointmentDay}{' '}
              {' from '}
              {appointmentTimeSlot}
            </b>
            <br />
            <b>
              Appointment Status: {existingAppointment?.status}
              <br />
            </b>
            <b>
              Appointment SubStatus: {existingAppointment?.subStatus}
              <br />
            </b>
            <b>
              Appointment Type:{' '}
              {existingAppointment?.bookingType.slice(0, 1).toUpperCase() +
                existingAppointment?.bookingType.slice(1)}
              <br />
            </b>
          </p>
          <div className='d-flex bd-highlight mb-2 mt-1 gap-2'>
            <Button
              variant='primary'
              onClick={() => setReassignDialogParent()}
              disabled={existingAppointment.status === 'Done' ? true : false}
            >
              Reassign Servicer
            </Button>
            <Button
              variant='primary'
              disabled={existingAppointment.status === 'Done' ? true : false}
              onClick={() => setCancelDialogParent()}
            >
              Cancel Service Order
            </Button>
            <Button
              variant='primary'
              onClick={() => setRescheduleDialogParent()}
              disabled={existingAppointment.status === 'Done' ? true : false}
            >
              Reschedule Appointment
            </Button>
          </div>
        </React.Fragment>
      )
    } else {
      return 'No service order has been selected or there is no appointment scheduled.'
    }
  }

  updateServicer = async () => {
    const { contractNumber, serviceOrderNumber } = this.props
    const { newDate, newTime, servicerName, servicerNumber, systemId } = this.state

    try {
      this.setState({
        isTransactionWorking: true,
      })
      const response = await updateServicer(
        serviceOrderNumber,
        servicerNumber,
        servicerName,
        systemId,
        newDate,
        newTime,
      )

      this.setState({
        isTransactionWorking: false,
      })

      const { data, status, statusText } = response
      let errorMessage = ''

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while updating appointment details:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data?.data
            errorMessage += `<br/>${errorDetails?.bookingMessage}`
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }
          this.setState({
            errorMessage,
            isTransactionWorking: false,
          })
        } else {
          this.handleAppointmentModalClose()
          this.props.getAppointment(contractNumber, serviceOrderNumber)
        }
      } else {
        errorMessage += `<br/><b>Error while updating appointment details:</b><br/> ${status} - ${statusText}`
        this.setState({
          errorMessage,
          isTransactionWorking: false,
        })
        return null
      }
    } catch (error) {
      let errorMessage = ''
      errorMessage += `<br/><b>Error before updating appointment details:</b><br/>${error.message}`
      this.setState({
        errorMessage,
        isTransactionWorking: false,
      })
    }
  }

  getServicerDetails = async () => {
    const { servicerNumber } = this.state
    this.setState({
      isServicerVerified: false,
      isServicerLookupWorking: true,
      errorMessage: '',
    })
    try {
      const response = await getServicer(servicerNumber)
      const { status, data, statusText } = response
      let errorMessage = this.state.errorMessage

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting servicer details:</b><br/>${data?.status} - ${data?.message}`
          errorMessage += `<br/>Attempted to search for servicer ${servicerNumber}.`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }
          this.setState({
            errorMessage,
            isServicerLookupWorking: false,
            isServicerVerified: false,
          })
        } else {
          this.setState({
            isServicerLookupWorking: false,
            isServicerVerified: true,
            servicerAddress1: data.data.servicerAddress.mailingAddress.address1,
            servicerAddress2: data.data.servicerAddress.mailingAddress.address2,
            servicerCity: data.data.servicerAddress.mailingAddress.city,
            servicerState: data.data.servicerAddress.mailingAddress.state,
            servicerCountry: data?.data?.servicerAddress?.mailingAddress?.country,
            servicerPostalCode: data.data.servicerAddress.mailingAddress.postalCode,
            servicerName: data.data.servicerName,
          })
        }
      } else {
        errorMessage += `<br/><b>Error while getting servicer details:</b><br/> ${status} - ${statusText}`
        this.setState({
          errorMessage,
          isServicerLookupWorking: false,
          isServicerVerified: false,
        })
        return null
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage
      errorMessage += `<br/><b>Error while getting new contract details:</b><br/>${error.message}`
      this.setState({
        errorMessage,
        isServicerLookupWorking: false,
        isServicerVerified: false,
      })
    }
  }

  handleAppointmentModalClose = () => {
    this.setState({ showNewAppointmentModal: false })
    this.handleModalReset()
  }

  handleAppointmentModalShow = () => {
    this.handleModalReset()
    this.setState({ showNewAppointmentModal: true })
  }

  render() {
    const { appointmentWorking } = this.props
    const {
      errorMessage,
      isNewDateValid,
      isNewDateValidated,
      isNewTimeValid,
      isNewTimeValidated,
      isServicerLookupWorking,
      isServicerValid,
      isServicerValidated,
      isServicerVerified,
      isSystemIdValid,
      isSystemIdValidated,
      isTransactionWorking,
      newDate,
      newTime,
      servicerAddress1,
      servicerAddress2,
      servicerCity,
      servicerName,
      servicerNumber,
      servicerPostalCode,
      servicerState,
      servicerCountry,
      showNewAppointmentModal,
      systemId,
    } = this.state
    return (
      <Fragment>
        <Card className='mt-3'>
          <Card.Header className='text-white'>Existing Appointment</Card.Header>
          <Card.Body>
            {appointmentWorking && (
              <Row className='mt-3'>
                <Col>
                  <Spinner animation='border'></Spinner>
                  <br />
                  <span className='spinner-text'>Booking appointment...</span>
                </Col>
              </Row>
            )}
            {!appointmentWorking && this.getAppointmentDetails()}
          </Card.Body>
        </Card>
        <Modal show={showNewAppointmentModal} keyboard={false}>
          <Modal.Header>
            <Modal.Title>New Appointment Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} controlId='servicerNumber' className='mb-3'>
              <Form.Label column xs={3}>
                Servicer ID:
              </Form.Label>
              <Col xs={5}>
                <Form.Control
                  autoComplete='off'
                  className={
                    !isServicerValidated ? null : isServicerValid ? 'is-valid' : 'is-invalid'
                  }
                  disabled={isServicerLookupWorking || isTransactionWorking}
                  placeholder='Servicer ID'
                  size='sm'
                  type='text'
                  value={servicerNumber}
                  onChange={(event) => {
                    this.setState({
                      servicerNumber: event.target.value,
                      newDate: moment(new Date()).format('MM/DD/YYYY'),
                      newTime: '',
                      systemId: '',
                      isServicerVerified: false,
                    })
                    this.handleValidationReset()
                  }}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a servicer number.
                </Form.Control.Feedback>
              </Col>
              <Col xs={4}>
                <Button
                  className='RoundedButton'
                  variant='primary'
                  disabled={isServicerLookupWorking || isTransactionWorking}
                  onClick={() => this.getServicerDetails()}
                >
                  Verify
                </Button>
              </Col>
            </Form.Group>
            {isServicerLookupWorking && (
              <Row className='mt-5 ps-3 pe-3'>
                <Col>
                  <Spinner animation='border'></Spinner>
                  <br />
                  <span className='spinner-text'>Finding servicer...</span>
                </Col>
              </Row>
            )}
            {!isServicerLookupWorking && isServicerVerified && (
              <Fragment>
                <Form.Group>
                  <Form.Text>{servicerName}</Form.Text>
                  <Form.Text>
                    <br />
                    {servicerAddress1}
                  </Form.Text>
                  {servicerAddress2?.length > 0 && (
                    <Form.Text>
                      <br />
                      {servicerAddress2}
                    </Form.Text>
                  )}
                  <Form.Text>
                    <br />
                    {servicerCity}
                    {', '}
                  </Form.Text>
                  <Form.Text>
                    {servicerState}
                    {'  '}
                  </Form.Text>
                  <Form.Text>{servicerCountry}</Form.Text>
                  <Form.Text>{servicerPostalCode}</Form.Text>
                </Form.Group>
                <Form.Group as={Row} controlId='systemId' className='mt-5'>
                  <Form.Label column xs={6}>
                    Servicer System ID:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      autoComplete='off'
                      className={
                        !isSystemIdValidated ? null : isSystemIdValid ? 'is-valid' : 'is-invalid'
                      }
                      disabled={isServicerLookupWorking || isTransactionWorking}
                      placeholder='Servicer System ID'
                      size='sm'
                      type='text'
                      value={systemId}
                      onChange={(event) => {
                        this.setState({
                          systemId: event.target.value,
                        })
                      }}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Please provide servicer system id.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='newDate'>
                  <Form.Label column xs={6}>
                    New Date:
                  </Form.Label>
                  <Col xs={5}>
                    <DatePicker
                      as={Form.Control}
                      className={
                        !isNewDateValidated ? null : isNewDateValid ? 'is-valid' : 'is-invalid'
                      }
                      disabled={isTransactionWorking || isServicerLookupWorking}
                      selected={new Date()}
                      size='sm'
                      onSelect={(date) => this.handleNewDateSelect(date)}
                      value={newDate}
                      minDate={new Date()}
                      disabledKeyboardNavigation
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a date.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='newTime'>
                  <Form.Label column xs={6}>
                    New Time:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      as='select'
                      className={
                        !isNewTimeValidated ? null : isNewTimeValid ? 'is-valid' : 'is-invalid'
                      }
                      disabled={isTransactionWorking || isServicerLookupWorking}
                      size='sm'
                      value={newTime}
                      onChange={(event) => {
                        this.setState({ newTime: event.target.value })
                      }}
                    >
                      <option key='' value=''>
                        Select time...
                      </option>
                      <option key='8-12' value='8-12'>
                        8-12
                      </option>
                      <option key='12-17' value='12-17'>
                        12-17
                      </option>
                      <option key='17-21' value='17-21'>
                        17-21
                      </option>
                      <option key='8-17' value='8-17'>
                        8-17
                      </option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Please provide a new time.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Fragment>
            )}
            {errorMessage.length > 0 ? (
              <Row className='mt-3 ps-3 pe-3'>
                <Col>
                  <Alert id='errorFound' variant='danger'>
                    {parse(errorMessage)}
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='RoundedButton'
              variant='secondary'
              onClick={() => this.handleAppointmentModalClose()}
              disabled={isTransactionWorking || isServicerLookupWorking}
            >
              Close
            </Button>
            <Button
              className='RoundedButton'
              disabled={isTransactionWorking || isServicerLookupWorking}
              onClick={() => this.handleUpdateServicer()}
              variant='primary'
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default ExistingAppointment
