import React from 'react'
import moment from 'moment'
import { Col, Row, Spinner, Modal, Button, Form, CloseButton } from 'react-bootstrap'
import AppointmentDay from './AppointmentDay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

class AppointmentList extends React.Component {
  state = {
    dates: [],
    showPopupAppointment: false,
    selectedAppointment: null,
    showAppointmentSuccess: false,
  }
  componentDidMount() {
    this.filterDates()
  }

  componentWillUnmount() {
    this.setState({ dates: [] })
  }

  filterDates() {
    const { beginSearchDate, procedureCode } = this.props
    let currentDate = moment(beginSearchDate, 'MM-DD-YYYY')
    var dateList = []
    for (let i = 0; i < 7; i++) {
      var appointmentList = []
      if (
        moment(currentDate).isBefore(moment(), 'day') ||
        (moment(currentDate).isSame(moment(), 'day') && procedureCode === 'Field Service')
      ) {
        // Don't add any appointments if before today or same day if Field Service
        dateList.push({
          date: moment(currentDate).format('MM/DD/YYYY'),
          appointmentList: [],
        })
      } else {
        appointmentList = this.props.appointmentList.filter(
          // eslint-disable-next-line
          (appointment) =>
            moment(appointment.appointmentInfo.timeSlotData.availableDate).isSame(
              moment(currentDate),
              'day',
            ),
        )
        dateList.push({
          date: moment(currentDate).format('MM/DD/YYYY'),
          appointmentList: appointmentList,
        })
      }

      currentDate = moment(currentDate).add(1, 'days')
    }

    this.setState({ dates: dateList })
  }

  displayGrid() {
    const {
      beginSearchDate,
      bookedAppointment,
      handleNextWeek,
      handleLastWeek,
      handleAppointmentSelect,
      selectedAppointment,
    } = this.props
    let showLast = true
    if (moment(beginSearchDate).isSameOrBefore(moment().format('MM/DD/YYYY'))) {
      showLast = false
    }

    return this.state.dates.map((date, i) => {
      return (
        <AppointmentDay
          key={date.date}
          date={date}
          showLast={showLast}
          index={i}
          total={this.state.dates.length}
          handleNextWeek={handleNextWeek}
          handleLastWeek={handleLastWeek}
          handleAppointmentSelect={handleAppointmentSelect}
          selectedAppointment={selectedAppointment}
          bookedAppointment={bookedAppointment}
          showPopupAppointmentDetail={this.showPopupAppointmentDetail.bind(this)}
        />
      )
    })
  }

  showPopupAppointmentDetail = async (appointment) => {
    this.setState({ showPopupAppointment: true, selectedAppointment: appointment })
  }

  render() {
    const {
      appointmentWorking,
      loading,
      handleBookAppointment,
      handleAppointmentSuccess,
      selectedContract,
    } = this.props
    const {
      showPopupAppointment,
      showAppointmentSuccess,
      selectedAppointment,
      serviceOrderNumber,
      externalAppointmentNumber,
      bookingNote,
    } = this.state
    const { customer } = selectedContract
    const { email, firstName, lastName, phone } = customer
    return (
      <React.Fragment>
        <Modal
          show={showPopupAppointment}
          keyboard={false}
          size='sm'
          style={{ 'margin-top': '10px' }}
        >
          <Modal.Header>
            {selectedAppointment && (
              <Modal.Title
                as={Row}
                style={{
                  'font-size': '0.9rem',
                  'text-align': 'end',
                }}
              >
                <CloseButton
                  style={{ 'margin-right': '5px' }}
                  onClick={() => {
                    this.setState({
                      showPopupAppointment: false,
                      selectedAppointment: null,
                      bookingNote: '',
                    })
                  }}
                />
                <div
                  style={{
                    margin: 'auto',
                    width: '100%',
                    'text-align': 'center',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      'font-size': '3.5rem',
                      background: 'aliceblue',
                      padding: '10px 15px 10px 15px',
                      'border-radius': '50%',
                      'margin-bottom': '5px',
                    }}
                    icon={faCalendar}
                  />
                </div>
                <p
                  style={{
                    'text-align': 'center',
                    'margin-bottom': '0px',
                  }}
                >
                  Do you want to schedule appointment for{' '}
                  {moment(selectedAppointment.appointmentInfo.timeSlotData.availableDate).format(
                    'dddd, MMMM DD, YYYY',
                  )}{' '}
                  ?
                </p>
              </Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                'background-color': 'aliceblue',
                'border-radius': '10px',
              }}
            >
              {selectedAppointment && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'font-weight': 'bold',
                    'margin-bottom': '5px',
                    'padding-top': '5px',
                    'padding-bottom': '5px',
                    'background-color': 'aqua',
                    'border-top-left-radius': '10px',
                    'border-top-right-radius': '10px',
                  }}
                >
                  {selectedAppointment.appointmentInfo.svcrName}
                </p>
              )}
              {selectedAppointment && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'font-weight': 'bold',
                    'margin-bottom': '5px',
                  }}
                >
                  <b style={{ 'margin-right': '3px' }}>Date :</b>
                  {selectedAppointment.appointmentInfo.timeSlotData.availableDate}
                </p>
              )}
              {selectedAppointment && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'font-weight': 'bold',
                    'margin-bottom': '5px',
                  }}
                >
                  <b style={{ 'margin-right': '3px' }}>Time :</b>
                  {selectedAppointment.appointmentInfo.timeSlotData.timePeriod}
                </p>
              )}
              {selectedAppointment && selectedAppointment.appointmentInfo.type === 'SRS' && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'margin-bottom': '5px',
                    padding: '5px 35px 5px 35px',
                  }}
                >
                  <b style={{ 'margin-right': '3px' }}>Notes :</b>Call us at 1-800-665-2127. We’ll
                  do our best to reschedule a time convenient for you or have your local service
                  unit contact you for rescheduling.
                </p>
              )}
            </div>
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '5px',
                  'margin-top': '10px',
                  'text-align': 'center',
                }}
              >
                <b>Enter Notes Here</b>
              </p>
            )}
            {selectedAppointment && (
              <Form.Group controlId='bookingNotes'>
                <Col xs={12}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Notes Here'
                    value={bookingNote}
                    onChange={(event) => {
                      this.setState({ bookingNote: event.target.value })
                    }}
                    size='xl'
                    tabIndex='7'
                  />
                </Col>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ width: '100%', 'text-align': 'center' }}>
              <Button
                style={{
                  'margin-right': '20px',
                }}
                className='cancleButton'
                onClick={() =>
                  this.setState({ showPopupAppointment: false, selectedAppointment: null })
                }
              >
                No
              </Button>
              <Button
                variant='primary'
                onClick={async () => {
                  this.setState({ showPopupAppointment: false })
                  let resp = await handleBookAppointment(selectedAppointment, bookingNote)
                  if (
                    resp &&
                    (resp.serviceOrderNumber !== null || resp.serviceOrderNumber !== undefined)
                  ) {
                    this.setState({
                      showAppointmentSuccess: true,
                      serviceOrderNumber: resp.serviceOrderNumber,
                      externalAppointmentNumber: resp.externalAppointmentNumber,
                    })
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showAppointmentSuccess}
          keyboard={false}
          size='sm'
          style={{ 'margin-top': '50px' }}
        >
          <Modal.Header>
            {selectedAppointment && (
              <Modal.Title
                as={Row}
                style={{
                  'font-size': '0.8rem',
                  'text-align': 'center',
                  'font-weight': 'bold',
                }}
              >
                <div
                  style={{
                    margin: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      'font-size': '3.5rem',
                      float: 'left',
                      background: 'aliceblue',
                      padding: '10px 15px 10px 15px',
                      'border-radius': '50%',
                      'margin-bottom': '5px',
                    }}
                    icon={faCalendar}
                  />
                </div>
                <p style={{ 'margin-bottom': '0' }}>
                  {selectedAppointment.appointmentInfo.svcrName} has been scheduled for
                </p>
                {serviceOrderNumber && (
                  <p style={{ 'margin-bottom': '0' }}>Service Order : {serviceOrderNumber}</p>
                )}
                <p style={{ 'margin-bottom': '0' }}>
                  {moment(selectedAppointment.appointmentInfo.timeSlotData.availableDate).format(
                    'dddd, MMMM DD, YYYY',
                  )}
                </p>
                <p style={{ 'margin-bottom': '10px' }}>
                  Time Slot {selectedAppointment.appointmentInfo.timeSlotData.timePeriod}
                </p>
                {externalAppointmentNumber && externalAppointmentNumber.split('-').length > 1 && (
                  <p style={{ 'margin-bottom': '0' }}>
                    {selectedAppointment.appointmentInfo.svcrName} Order Number :{' '}
                    {externalAppointmentNumber.split('-')[1]}
                  </p>
                )}
              </Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '10px',
                  'text-align': 'center',
                }}
              >
                Dispatch notification has been sent to the Consumer {firstName || ''}{' '}
                {lastName || ''} at
              </p>
            )}
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '5px',
                  'text-align': 'center',
                }}
              >
                {phone || ''}
              </p>
            )}
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '5px',
                  'text-align': 'center',
                }}
              >
                and {email || ''}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ width: '100%', 'text-align': 'center' }}>
              <Button variant='primary' onClick={() => handleAppointmentSuccess()}>
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        {loading === false ? (
          <React.Fragment>
            {appointmentWorking && (
              <Row className='mt-5 ps-3 pe-3'>
                <Col>
                  <Spinner animation='border'></Spinner>
                  <br />
                  <span className='spinner-text'>Booking appointment...</span>
                </Col>
              </Row>
            )}
            {!appointmentWorking && <Row className='ps-3 pe-3'>{this.displayGrid()}</Row>}
          </React.Fragment>
        ) : (
          <Spinner style={{ 'margin-top': '100px' }} animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        )}
      </React.Fragment>
    )
  }
}

export default AppointmentList
