import * as React from 'react'
import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from '@mui/material'
import {ArrowBackIosNew, ArrowForwardIos, Close} from '@mui/icons-material'
import toast from 'react-hot-toast'
import Afternoon from '../../img/Afternoon.svg'
import Evening from '../../img/Evening.svg'
import firm from '../../img/appointmentfirm.svg'
import Morning from '../../img/Morning.svg'
import NotAfterNoon from '../../img/Afternoon-not-available.svg'
import NotEvening from '../../img/Evening-not-available.svg'
import NotMorning from '../../img/Morning-not-available.svg'
import Rejected from '../../img/appointment (1) (2) (1)rejected.svg'
import SoftAvailability from '../../img/appointment (1) (2) (1).svg'
import { getSoftAvailabilityServicerDetail } from '../../api/serviceavenger'
import './../App.css'

function ReassignDialog({
  totalReassignData,
  setReassignIndex,
  setAppointmentDay,
  setHandleConfirmDialog,
  setAppointmentDate,
  setFinalBookingTimeSlot, // This is not used, remove
  setFirmAvilabilityDialog,
  reassignOpen,
  handleClose,
  data,
  setBookingContent,
  servicerData,
  setActivefirmAvailabilitydialog,
  setReassignData,
  serviceOrder,
  uiservicerData,
}) {
  const [increaseBtnDisable, setIncreaseBtnDisable] = useState(true)
  const [decreaseBtnDisable, setDecreaseBtnDisableBtnDisable] = useState(false)

  const handleBooking = async (subItems, item, subIndex) => {
    setAppointmentDate(item?.date)
    setAppointmentDay(item?.day)
    setReassignIndex(subIndex)

    if (subItems?.svcrType === 'WEB') {
      try {
        const res = await getSoftAvailabilityServicerDetail(subItems)
        if (res?.data?.isSuccess) {
          const newData = {
            ...subItems,
            dispatchAddress: res?.data?.data.dispatchAddress,
            dispatchMethodPhone: res?.data?.data.dispatchMethodPhone,
            dispatchMethodEmail: res?.data?.data.dispatchMethodEmail,
            servicerAccountId: res?.data?.data.servicerAccountId,
            notes: res?.data?.data.notes,
          }
          const bookingData = newData
          setBookingContent(bookingData)
          setHandleConfirmDialog(true)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    } else {
      setBookingContent(subItems)
      setHandleConfirmDialog(true)
    }
  }

  const handleBackward = () => {
    const firstSeven = totalReassignData.slice(0, 7)
    setReassignData(firstSeven)
    setIncreaseBtnDisable(true)
    setDecreaseBtnDisableBtnDisable(false)
  }
  const handleForward = () => {
    const lastSeven = totalReassignData.slice(-7)
    setReassignData(lastSeven)
    setIncreaseBtnDisable(false)
    setDecreaseBtnDisableBtnDisable(true)
  }
  const isTimeSlotPresent = (availability, str) => {
    return availability && availability.some((slot) => slot.slotName === str)
  }
  return (
    <React.Fragment>
      {servicerData?.bookingType !== 'firm' && (
        <Dialog
          aria-labelledby='customized-dialog-title'
          open={reassignOpen}
          PaperProps={{
            sx: {
              maxWidth: '100vw', 
              width: '100vw', 
              height: '97vh',
              borderRadius: '20px',
              paddingRight: '1rem',
              paddingLeft: '1rem',
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '28px',
            }}
            id='customized-dialog-title'
          >
            Reassign Servicer <br />
            (Current Service Provider : {uiservicerData?.account?.businessName}-
            {serviceOrder?.servicerAccountId}, {serviceOrder?.appointmentDay}{' '}
            {serviceOrder?.appointmentDate}, {serviceOrder?.appointmentTimeSlot})
          </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent className='custom-scrollbar' sx={{ overflowY: 'scroll' }}>
            <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }}>
              {data?.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={1.7}
                  style={{
                    borderLeft: '.5px dotted #EFEFEF',
                    borderRight: '.5px dotted #EFEFEF',
                    borderBottom: '.5px dotted #EFEFEF',
                  }}
                >
                  <List
                    className='custom-scrollbar'
                    sx={{
                      overflowY: 'hidden',
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                      padding: '0',
                      position: 'relative',
                    }}
                    component='nav'
                    aria-labelledby='nested-list-subheader'
                    subheader={
                      <ListSubheader
                        style={{
                          background: '#F2F4FF',
                          textAlign: 'center',
                          fontSize: '14px',
                          position: 'sticky',
                          top: 0,
                          lineHeight: '1.8rem',
                        }}
                        component='div'
                        id='nested-list-subheader'
                      >
                        {index === 0 && (
                          <ArrowBackIosNew
                            style={{ fontSize: '12px', position: 'absolute', left: 20, top: 20 }}
                            onClick={() => decreaseBtnDisable && handleBackward()}
                          />
                        )}
                        {item.day} <br />
                        {item.date}
                        {index === data.length - 1 && (
                          <ArrowForwardIos
                            style={{ fontSize: '12px', position: 'absolute', right: 20, top: 20 }}
                            onClick={() => increaseBtnDisable && handleForward()}
                          />
                        )}
                      </ListSubheader>
                    }
                  >
                    {item.availability.map((subItems, subIndex) => (
                      <ListItemButton
                        style={{
                          marginTop: '1rem',
                          width: '100%',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          paddingBottom: '0px',
                          border: '1px solid rgba(234, 234, 234, 1)',
                        }}
                      >
                        <Grid container xs={12}>
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{ padding: '0px' }}
                            style={{ alignItems: 'center' }}
                          >
                            <Grid item xs={3}>
                              {subItems?.isRejected ? (
                                <img src={Rejected} alt='user-pic' height='24px' width='24px' />
                              ) : subItems?.serviceProvider === 'A&E' && !subItems?.isRejected ? (
                                <img src={firm} alt='user-pic' height='24px' width='24px' />
                              ) : (
                                <img
                                  src={SoftAvailability}
                                  alt='user-pic'
                                  height='24px'
                                  width='24px'
                                />
                              )}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                              <Typography
                                onClick={() => handleBooking(subItems, item, subIndex)}
                                sx={{
                                  fontSize: '11px',
                                  wordWrap: 'break-word',
                                  color: 'rgba(2, 160, 252, 1)',
                                  cursor: 'pointer',
                                }}
                              >
                                {subItems?.serviceProvider}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                          <Grid container item xs={12} style={{ alignItems: 'top' }}>
                            {subItems?.serviceProvider !== 'A&E' && subItems?.provider !== 'A&E' ? (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '12px' }}>Availability : </span>
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'morning') ? (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={Morning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={NotMorning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'afternoon') ? (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={Afternoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={NotAfterNoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'evening') ? (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={Evening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '3px', marginTop: '3px' }}>
                                      <img
                                        src={NotEvening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '12px' }}>Availability : </span>
                                </Grid>
                                {subItems?.timeSlot?.map((timeData) => (
                                  <Grid item style={{ alignItems: 'top' }}>
                                    <Grid
                                      style={{
                                        border: '1px solid rgba(234, 234, 234, 1)',
                                        borderRadius: '8px',
                                        background: 'rgba(245, 245, 245, 1)',
                                        marginLeft: '5px',
                                        marginBottom: '10px',
                                        padding: '0 3px',
                                        height: '24px',
                                      }}
                                    >
                                      <span style={{ fontSize: '11px' }}>
                                        {' '}
                                        {timeData?.slotName}
                                      </span>
                                    </Grid>
                                  </Grid>
                                ))}
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingBottom: '2rem' }}>
            <Grid container xs={12} style={{ fontSize: '14px', alignItems: 'center' }}>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SoftAvailability} alt='user-pic' height='24px' width='24px' />
                &nbsp;&nbsp; Soft Availability
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={firm} alt='user-pic' height='24px' width='24px' />
                &nbsp; &nbsp; Firm Availability
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Rejected} alt='user-pic' height='24px' width='24px' />
                &nbsp;&nbsp; Rejected
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {servicerData.bookingType === 'firm' && (
        <Dialog
          aria-labelledby='customized-dialog-title'
          open={reassignOpen}
          PaperProps={{
            sx: {
              maxWidth: '28rem',
              width: '28rem',
              height: '23rem',
              borderRadius: '20px',
              paddingRight: '1rem',
              paddingLeft: '1rem',
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '28px',
              color: 'red',
            }}
            id='customized-dialog-title'
          >
            Attention
          </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <Grid
              style={{
                height: '5rem',
                borderRadius: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'center',
                fontSize: '14px',
                background: 'rgba(229, 102, 79, 0.12)',
                paddingLeft: '1rem',
                paddingRight: '.6rem',
              }}
            >
              <span style={{ fontSize: '14px' }}>
                Current Service Provider for Servicer Order ID {serviceOrder.serviceOrderId} is A&E
                Factory Service
              </span>
            </Grid>
            <Grid
              style={{
                marginTop: '1rem',
                height: '5rem',
                borderRadius: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'center',
                fontSize: '14px',
              }}
            >
              <span style={{ fontSize: '14px' }}>
                Are you sure you want to reassign a non A&E Servicer to the Service Order ID{' '}
                {serviceOrder.serviceOrderId} ?
              </span>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingBottom: '2rem' }}>
            <Grid
              container
              xs={12}
              style={{
                justifyContent: 'center',
                fontSize: '14px',
                alignItems: 'center',
                textTransform: 'none',
              }}
            >
              <Button
                variant='contained'
                onClick={() => {
                  setFirmAvilabilityDialog(true)
                  handleClose()
                }}
              >
                <span style={{ textTransform: 'none' }}>Not Sure</span>
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  handleClose(false)
                  setActivefirmAvailabilitydialog(2)
                  setFirmAvilabilityDialog(true)
                }}
                style={{ marginLeft: '20px' }}
              >
                <span style={{ textTransform: 'none' }}> Sure</span>
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default ReassignDialog
