import * as React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import moment from 'moment'
import Icon from '../../img/delete 1FInalConfirmation.svg'

export default function ConfirmationRescheduleDialog({
  open,
  handleClose,
  data,
  servicer,
  consumer,
  finalBookingTimeSlot,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      PaperProps={{
        style: {
          borderRadius: '20px',
          width: '29rem',
          height: '30rem',
        },
      }}
    >
      <DialogTitle className='text-center' sx={{ m: 2, p: 2, mb: 0 }} id='customized-dialog-title'>
        <img
          src={Icon}
          alt='user-pic'
          height='45px'
          width='45px'
          className='rounded-circle p-2'
          style={{ background: 'rgba(235, 248, 255, 1)' }}
        />
      </DialogTitle>

      <DialogContent className='text-center'>
        <Grid>
          <span className='fw-semibold fs-5'>
            {servicer?.account?.businessName} has been rescheduled to Service Order #
            {consumer?.serviceOrder?.providerType === 'A&E'
              ? data?.callNumber
              : data?.serviceOrderNumber}
            &nbsp; for {moment(finalBookingTimeSlot?.appointmentDate).format('MMMM DD, YYYY')},{' '}
            {finalBookingTimeSlot?.appointmentTimeSlot}
          </span>
        </Grid>

        <Grid className='mt-3'>
          <span style={{ fontSize: '200' }}>
            Reschedule notification has been sent to Consumer <span> </span>
            {consumer?.consumer?.customerName} at <span> </span>
            {consumer?.consumer?.phone} and <span> </span>
            {consumer?.consumer?.email}.{' '}
          </span>
        </Grid>

        <Grid className='mt-3'>
          <span>
            Reschedule notification has been sent to Consumer {consumer?.consumer?.customerName} at{' '}
            {consumer?.consumer?.phone} and {consumer?.consumer?.email}.
          </span>
        </Grid>

        <br />
        <Grid item xs={12} className='d-flex justify-content-center mt-4'>
          <Button variant='contained' onClick={handleClose}>
            Okay
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
