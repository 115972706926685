/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { Accordion, ListGroup } from 'react-bootstrap'
import './Appointment.css'
import './Accordion.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faCalendarMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class Appointment extends Component {
  displayAppointments(appointments) {
    if (appointments && appointments.length) {
      if (appointments[0].svcrName !== 'A&E') {
        const orderMapping = { morning: 0, evening: 2, afternoon: 1 }
        function customSort(a, b) {
          return (
            orderMapping[a.appointmentInfo.timeSlotData.timePeriod] -
            orderMapping[b.appointmentInfo.timeSlotData.timePeriod]
          )
        }
        appointments.sort(customSort)
      }
      return appointments.map((appointment) => {
        return (
          <ListGroup.Item
            className={`text-dark bg-white border border-dark p-1 rounded small ${this.getClassName(
              appointment,
            )}`}
            variant={this.getVariant(appointment)}
            onClick={this.getOnClick(appointment)}
          >
            {appointment.appointmentInfo.timeSlotData.timePeriod}
          </ListGroup.Item>
        )
      })
    }
  }

  getClassName() {
    const { selectedAppointment, appointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'text-center fw-bold selectedAppointment'
    } else {
      return appointment === null ? 'text-center' : 'text-center availableAppointment'
    }
  }

  getVariant(appointment) {
    const { selectedAppointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'warning'
    } else {
      return appointment === null ? 'light' : 'success'
    }
  }

  getAppointmentAvailability(appointment) {
    const { selectedAppointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'Selected'
    } else {
      return appointment === null ? 'Unavailable' : 'Available'
    }
  }

  getOnClick(appointment) {
    const { selectedAppointment, showPopupAppointmentDetail } = this.props

    if (appointment != null && appointment !== selectedAppointment) {
      return () => showPopupAppointmentDetail(appointment)
    } else if (appointment != null && appointment === selectedAppointment) {
      return () => showPopupAppointmentDetail(null)
    } else {
      return null
    }
  }

  render() {
    return (
      <Accordion.Item
        eventKey={this.props.index}
        className={`text-dark bg-white border border-gray p-2 rounded-1 mx-auto mb-2`}
        style={{ width: '120px', fontSize: '0.5rem' }}
      >
        <Accordion.Header>
          <div
            className={`text-left overflow-hidden text-truncate d-inline-block`}
            style={{ width: '100px', lineHeight: '1.5' }}
          >
            {this.props.type === 'SRS' ? (
              <FontAwesomeIcon
                className={`text-info me-1`}
                style={{ fontSize: '0.7rem' }}
                icon={faCalendarPlus}
              />
            ) : (
              <FontAwesomeIcon
                className={`me-1`}
                style={{ fontSize: '0.7rem' }}
                icon={faCalendarMinus}
              />
            )}
            {this.props.servicer}
          </div>
          <FontAwesomeIcon
            className={`text-info`}
            style={{ fontSize: '0.7rem' }}
            icon={faPlusCircle}
          />
        </Accordion.Header>
        <Accordion.Body className={`p-2 text-left`}>
          <p className={`mb-2`}>Availability:</p>
          <ListGroup variant='flush'>{this.displayAppointments(this.props.appointments)}</ListGroup>
          {this.props.appointment.svcrName === 'A&E' && (
            <p className={`fs-6 fw-bold mb-1`}>Notes:</p>
          )}
          {this.props.appointment.svcrName === 'A&E' && (
            <p className={`fs-6 mb-1`}>
              Call us at 1-800-665-2127. We’ll do our best to reschedule a time convenient for you
              or have your local service unit contact you for rescheduling.
            </p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default Appointment
