import axios from 'axios'
import moment from 'moment'

let baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL

export const cancelAppointment = async (cancelReasonCode, customerId, existingAppointment) => {
  const {
    appointmentDate,
    appointmentTimeSlot,
    customerCellphone,
    customerFirstName,
    customerLastName,
    externalContractNumber,
    productSequenceNumber,
    serviceAvengerContractNumber,
    serviceOrderNumber,
    serviceRequestType,
    servicerID,
    servicerName,
    servicerPhone,
    servicerWebsite,
    serviceSite,
    authorizationNumber,
    providerType,
  } = existingAppointment

  return await axios(baseURL + 'standard/cancel/serviceappointment', {
    method: 'POST',
    data: {
      serviceAvengerContractNumber,
      externalContractNumber,
      externalOrderNumber: '', // (Leave blank)
      serviceOrderNumber,
      servicerSource: 'SP', // (SP = Service Power)
      crmKey: customerId,
      firstName: customerFirstName,
      lastName: customerLastName,
      consumerPhone: customerCellphone,
      productSeqeuenceNumber: productSequenceNumber,
      servicerID,
      appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
      appointmentTimeSlot: appointmentTimeSlot,
      serviceType: serviceRequestType,
      serviceLocation: serviceSite,
      servicerName,
      servicerPhone,
      servicerWebsite,
      cancelReason: cancelReasonCode,
      authorizationNumber,
      providerType,
    },
  })
}

export const getCancelReasons = async () => {
  return await axios(baseURL + 'standard/variables/getcancelreasons', {
    method: 'POST',
    data: {
      serviceNetworkID: 'SP', //SP = ServicePower
    },
  })
}

export const getExternalPayments = async (contractNumber, externalContractNumber) => {
  return await axios(baseURL + 'standard/lookups/externalpayments', {
    method: 'POST',

    data: {
      serviceAvengerContractNumber: contractNumber,
      externalContractNumber: externalContractNumber,
      dealerNumber: externalContractNumber,
    },
  })
}

export const getServiceOrders = async (contractNumber) => {
  return await axios(baseURL + 'standard/lookups/getserviceorders', {
    method: 'POST',

    data: {
      serviceAvengerContractNumber: contractNumber,
    },
  })
}

export const getFailureTypes = async (tierCode) => {
  return await axios(baseURL + 'standard/variables/getfailuretypes', {
    method: 'POST',

    data: {
      tierID: tierCode,
    },
  })
}

export const getAppointments = async (
  address1 = '',
  address2 = '',
  bookingType = '',
  businessName = '',
  cellPhone = '',
  city = '',
  countryCode = '',
  customerId = '',
  dealerId = '',
  email = '',
  firstName = '',
  lastName = '',
  otherPhone = '',
  stateProvince = '',
  zipCode = '',
  brandCode = '',
  modelNumber = '',
  productCode = '',
  productTierCode = '',
  serialNumber = '',
  beginSearchDate = '',
  procedureCode = '',
  serviceElevation = '',
  serviceNotice = '',
  serviceType = '',
  contractNumber = '',
  beginDt = '',
  endDt = '',
  warrantyType = '',
  warrantyTypeId = '',
  serviceTypeId = '',
  serviceLocationId = '',
  sequenceNumber = '',
  providerType = '',
  crmKey = '',
  installDate = '',
  totalLiability = '',
  serviceOrderNumber = '',
  countryId = '',
  type = '',
) => {
  return await axios(baseURL + 'standard/provider/availability', {
    method: 'POST',

    data: {
      customer: {
        address1,
        address2,
        businessName,
        cellPhone,
        city,
        countryCode,
        customerId,
        crmKey,
        email,
        firstName,
        lastName,
        otherPhone,
        stateProvince,
        zipCode,
      },
      product: {
        brandCode,
        installDate,
        modelNumber,
        productCode,
        serialNumber,
        tierCode: productTierCode,
        totalLiability,
        productSequenceNumber: sequenceNumber,
      },
      service: {
        beginSearchDate: beginSearchDate || moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        numberOfDays: 14,
        procedureCode,
        serviceElevation: serviceElevation?.trim().length > 0 ? serviceElevation?.trim() : 'Normal',
        serviceNotice,
        serviceType,
        bookingType,
        dealerID: dealerId,
        serviceLocationId: serviceLocationId,
        serviceTypeId: serviceTypeId,
        providerType: providerType || '',
        serviceOrderNumber,
      },
      warranty: {
        contractNumber,
        coverageBeginDate: moment(beginDt).format('YYYY-MM-DD'),
        coverageEndDate: moment(endDt).format('YYYY-MM-DD'),
        warrantyType,
        warrantyTypeId: warrantyTypeId,
      },
      type: type || 'availability',
      countryId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    },
  })
}

export const updateProduct = async (
  contractNumber,
  dealerCustomerID,
  externalContractNumber,
  sequenceNumber,
  subcategory,
  brand,
  modelNumber,
  serial,
  serviceLocation,
  productPurchaseDate, 
) => {
  return await axios(baseURL + 'wholehome/updateproduct/productupdate', {
    method: 'POST',

    data: {
      contractInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        dealerCustomerID,
      },
      productInfo: {
        productSequenceNumber: sequenceNumber,
        productSubCategory: subcategory,
        manufacturerName: brand,
        modelNumber,
        serialNumber: serial,
        serviceLocation,
        productPurchaseDate, 
      },
    },
  })
}

export const addProduct = async (
  externalContractNumber,
  onPointContractNumber,
  dealerCustomerID,
  firstName,
  lastName,
  address1,
  address2,
  city,
  state,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  country,
  productSequenceNumber,
  productSubCategory,
  manufacturerName,
  modelNumber,
  serialNumber,
  productCondition,
  serviceLocation,
  installDt,
  purchaseDt,
) => {
  return await axios(baseURL + 'wholehome/addproduct/productupdate', {
    method: 'POST',

    data: {
      contractInfo: {
        externalContractNumber,
        onPointContractNumber,
        dealerCustomerID,
      },
      customerInfo: {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        country,
      },
      productInfo: {
        productSequenceNumber,
        productCategory: 'Major Appliance',
        productSubCategory,
        manufacturerName,
        modelNumber,
        serialNumber,
        productCondition,
        productPurchasePrice: 500,
        productPurchaseDate: moment(purchaseDt).format('YYYY-MM-DD'),
        productInstallDate: moment(installDt).format('YYYY-MM-DD'),
        serviceLocation,
      },
      misc: {
        miscGeneral1: '',
      },
    },
  })
}

export const getContractDetails = async (contractNumber) => {
  return await axios(baseURL + 'standard/OPgetcontract/details', {
    method: 'POST',
    data: {
      onPointContractNumber: contractNumber,
    },
  })
}

export const getBookedAppointment = async (contractNumber, serviceOrderNumber) => {
  return await axios(baseURL + 'standard/lookups/getbookedappointments', {
    method: 'POST',

    data: {
      serviceAvengerContractNumber: contractNumber,
      serviceOrderNumber: serviceOrderNumber,
    },
  })
}

export const getManufacturers = async () => {
  return await axios(baseURL + 'standard/variables/getmanufacturers', {
    method: 'GET',
  })
}

export const getServiceOrderDetails = async (serviceOrderNumber) => {
  return await axios(baseURL + 'standard/lookups/claimdetails', {
    method: 'POST',

    data: {
      serviceOrderNumber,
    },
  })
}

export const getSubcategories = async () => {
  return await axios(baseURL + 'standard/variables/getsubcategories', {
    method: 'GET',
  })
}

export const bookAppointment = async (reqBody) => {
  return await axios(baseURL + 'standard/book/appointment', {
    method: 'POST',

    data: {
      ...reqBody,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    },
  })
}

export const rebookAppointment = async (
  bookingType,
  firstName,
  lastName,
  businessName,
  address1,
  address2,
  city,
  stateProvince,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  countryCode,
  customerId,
  sequenceNumber,
  brandCode,
  catCode,
  productCode,
  productTierCode,
  modelNumber,
  serialNumber,
  warrantyType,
  coverageBeginDate,
  coverageEndDate,
  serviceAvengerContractNumber,
  externalContractNumber,
  failureDate,
  serviceRequestType,
  serviceElevation,
  serviceOrderNumber,
  externalAppointmentNumber,
  providerType,
  serviceSite,
  failureType,
  functionalityStatus,
  symptomCode,
  failureNote,
  selectedAppointment,
  specialInstructions,
  installDt,
) => {
  const { appointmentInfo } = selectedAppointment
  const {
    appointmentBrand,
    appointmentProduct,
    timeSlotData,
    svcrPhone,
    svcrName,
    serviceCenterID,
  } = appointmentInfo
  const { svcrID, offerID, availableDate, timePeriod } = timeSlotData

  return await axios(baseURL + 'standard/booking/serviceappointment', {
    method: 'POST',

    data: {
      customer: {
        firstName,
        lastName,
        businessName,
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        countryCode,
        crmKey: customerId,
      },
      product: {
        sequenceNumber,
        brandCode,
        productCode,
        appointmentBrand,
        appointmentProduct,
        catCode,
        tierCode: productTierCode,
        modelNumber,
        serialNumber,
        installDate: moment(installDt).format('YYYY-MM-DD'),
      },
      warranty: {
        warrantyType,
        coverageBeginDate: moment(coverageBeginDate).format('YYYY-MM-DD'),
        coverageEndDate: moment(coverageEndDate).format('YYYY-MM-DD'),
        serviceAvengerContractNumber,
        externalContractNumber,
      },
      service: {
        serviceOrderNumber,
        externalAppointmentNumber,
        providerType,
        serviceCallDate: moment().format('YYYY-MM-DD'),
        failureDate: moment(failureDate).format('YYYY-MM-DD'),
        serviceRequestType,
        serviceElevation,
        serviceSite,
        failureType,
        functionalityStatus,
        symptomCode,
        failureNote,
        bookingType,
      },
      appointment: {
        groupID: svcrID,
        offerKey: offerID,
        appointmentDate: availableDate ? moment(availableDate).format('YYYY-MM-DD') : '',
        appointmentTimeSlot: timePeriod,
        consumerRequestDate: availableDate ? moment(availableDate).format('YYYY-MM-DD') : '',
        consumerRequestTime: timePeriod,
        specialInstructions,
      },
      servicer: {
        servicerID: serviceCenterID,
        servicerName: svcrName,
        servicerPhone: svcrPhone,
        servicerWebsite: '',
      },
      parts: {
        partDistibutorID: '',
        partDistributorName: '',
        partNumber: '',
        partDescription: '',
        partQuantity: 0,
        partPrice: 0.0,
        shipperCompany: '',
        shipperTracking: '',
      },
      payment: {
        deductible: 0.0,
        poNumber: '',
        poAmount: 0.0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500.0,
      },
      additionalFunctions: {
        status1: 'Appointment Change',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
    },
  })
}

export const createFNOL = async (
  address1,
  address2,
  city,
  countryCode,
  deductible,
  claimFailureNote,
  claimFailureType,
  claimFunctionalityStatus,
  claimRequestPriority,
  claimStorySymptom,
  contractNumber,
  customerId,
  email,
  externalContractNumber,
  failureDt,
  firstName,
  hasConcessionFlg,
  lastName,
  mobileNumber,
  sequenceNumber,
  serviceNotice,
  servicerNotes,
  serviceType,
  state,
  zipCode,
  isFnolCalled,
  customerRequestedDate,
  customerRequestedWindow,
) => {
  let functionalStatus
  let failureType
  let claimReimbursementMethod = serviceNotice
  let claimStatusApproveReject
  switch (serviceType) {
    case 'Standard Exchange':
      claimStatusApproveReject = 'Standard Exchange'
      break
    case 'Advanced Exchange':
      claimStatusApproveReject = 'Advanced Exchange'
      break
    case 'Outbound Shipment Only':
      claimStatusApproveReject = 'Outbound Shipment Only'
      break
    default:
      if (deductible > 0 && hasConcessionFlg === true) {
        claimStatusApproveReject = 'Approved'
      } else {
        claimStatusApproveReject = 'Deductible'
      }
      break
  }

  switch (claimFunctionalityStatus) {
    case 'Functional':
      functionalStatus = 'F'
      break
    case 'Non-functional':
      functionalStatus = 'N'
      break
    case 'Intermittent':
      functionalStatus = 'I'
      break
    default:
      functionalStatus = 'F'
  }

  switch (claimFailureType) {
    case 'Standard':
      failureType = 'ST'
      break
    case 'Accidental Damage':
      failureType = 'AD'
      break
    case 'Theft':
      failureType = 'TH'
      break
    default:
      failureType = 'ST'
  }

  if (deductible > 0 && hasConcessionFlg) {
    claimReimbursementMethod = 'Concession'
  }
  if (serviceType === 'Advanced Exchange' && hasConcessionFlg) {
    claimReimbursementMethod = 'Concession'
  }

  return await axios(baseURL + 'standard/claims/fnolrequest', {
    method: 'POST',

    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        productSequenceNumber: sequenceNumber,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email,
        address1,
        address2,
        city,
        state,
        zipCode,
        country: countryCode,
        mobileNumber: mobileNumber.replace(/\D/g, ''),
      },
      claimInfo: {
        serviceType: 'Service',
        callerType: 'Customer',
        externalClaimNumber: 'UNKNOWN',
        claimRequestPriority,
        claimDateConsumerFiled: moment(failureDt).format('YYYY-MM-DD'),
        claimDateRepaired: '',
        claimDateAdjudicated: '',
        claimStatusApproveReject,
        claimAmount: 0.0,
        claimReimbursementAmount: 0.0,
        claimReimbursementMethod,
        claimStorySymptom,
        claimFunctionalityStatus: functionalStatus,
        claimFailureType: failureType,
        claimFailureNote,
        claimPaymentMethodAccount: sessionStorage.getItem('email'),
      },
      appointmentInfo: {
        preferenceDate1: moment(customerRequestedDate).format('YYYY-MM-DD'),
        preferenceTimeSlot1: customerRequestedWindow,
        preferenceDate2: null,
        preferenceTimeSlot2: null,
        preferenceDate3: null,
        preferenceTimeSlot3: null,
        appointmentNotes: servicerNotes,
        preferredServicerName: '',
        preferredServicerAddress1: '',
        preferredServicerAddress2: '',
        preferredServicerCity: '',
        preferredServicerState: '',
        preferredServicerZipcode: '',
        preferredServicerPhone: '',
        crmKey: customerId,
      },
    },
  })
}

export const getSubcategoriesByBrand = async (brand) => {
  return await axios(baseURL + 'standard/storedprocedures/getbrandsfromxref', {
    method: 'POST',

    data: {
      lookupType: brand,
      brandCode: '',
      productCode: '',
    },
  })
}

export const getNewContractModels = async (brandCode, productCode) => {
  return await axios(baseURL + 'standard/storedprocedures/getmodelsfromxref', {
    method: 'POST',

    data: {
      brandCode,
      productCode,
    },
  })
}

export const createContract = async (
  firstName,
  lastName,
  address1,
  address2,
  city,
  stateProvince,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  subcategory,
  brand,
  model,
  serial,
  purchaseDt,
  installDt,
  subCatCode,
  dealerInvoiceNumber,
  countryCode,
) => {
  return await axios(baseURL + 'standard/avengerui/createcontract/initialcontract', {
    method: 'POST',

    data: {
      customer: {
        firstName,
        lastName,
        businessName: '',
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        countryCode,
        dealerCustomerID: '',
        email,
        languageCode: 'en-us',
      },
      contract: {
        contractPurchaseDate: moment(installDt).format('YYYY-MM-DD'),
        contractOtherTax: 0,
        contractRetailPrice: 1.0,
        contractSalesTax: 0,
        contractSKU: subcategory,
        contractSourceName: brand,
        dealerID: 'NA',
        dealerInvoiceNumber,
      },
      product: {
        catalogItemNumber: '1',
        manufacturerName: brand,
        modelName: subcategory,
        modelNumber: model,
        oemLaborWarrantyTerm: '0',
        oemMajorCompTerm: '0',
        oemOtherTerm: '0',
        oemPartsWarrantyTerm: '0',
        productCondition: 'N',
        productDeliveryInstallDate: moment(installDt).format('YYYY-MM-DD'),
        productPurchaseDate: moment(purchaseDt).format('YYYY-MM-DD'),
        productPurchasePrice: 600,
        serialNumber: serial,
        subCategory: subCatCode,
      },
      general1: '',
      general2: '',
      general3: '',
      general4: '',
    },
  })
}

export const getServicer = async (servicerNumber) => {
  return await axios(baseURL + 'standard/lookups/servicerlookup', {
    method: 'POST',

    data: { servicerNumber },
  })
}

export const updateServicer = async (
  serviceOrderNumber,
  servicerNumber,
  servicerName,
  servicerSystemId,
  appointmentDate,
  appointmentTimeSlot,
) => {
  return await axios(baseURL + 'standard/claims/update/serviceprovider', {
    method: 'POST',

    data: {
      serviceOrderNumber,
      servicerNumber,
      servicerName,
      servicerPhone: '',
      servicerSystemID: servicerSystemId,
      appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
      appointmentTimeSlot,
    },
  })
}

export const processReimbursement = async (
  address,
  city,
  claimPayment,
  claimReimbursementMethod,
  contractNumber,
  correctiveActionDescription,
  countryCode,
  dealerPricingGroupNumber,
  email,
  externalContractNumber,
  failureCode,
  firstName,
  foodLoss,
  freight,
  hasSubclaimFlg,
  labor,
  lastName,
  mileage,
  parts,
  phone,
  postalCode,
  repairDt,
  serviceOrderNumber,
  serviceOrderNumberSub,
  state,
  tax,
  travel,
) => {
  return await axios(baseURL + 'standard/approvedclaims/payouts', {
    method: 'POST',

    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        paymentNoticeName: dealerPricingGroupNumber,
        serviceOrderNumber: hasSubclaimFlg ? serviceOrderNumberSub : serviceOrderNumber,
        sequenceNumber: 1,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email: email,
        address1: address,
        address2: '',
        city,
        state,
        zipCode: postalCode,
        country: countryCode,
        mobileNumber: phone,
      },
      claimInfo: {
        claimCashDisbursementReason: 'Reimbursement',
        claimStoryReason: failureCode,
        claimCorrectiveAction: 'Repaired',
        claimCorrectionDescription: correctiveActionDescription,
        claimDateConsumerFiled: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimFailureCauseType: 'Normal Wear And Tear',
        claimTechnicianName: 'Customer Dispatch',
        claimDateRepaired: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimOutcome: 'Repaired',
        claimDateAdjudicated: moment.utc().format('YYYY-MM-DD'),
        claimStatusApproveReject: 'Approve',
        claimAmountTotal: claimPayment,
        claimLaborAmount: labor,
        claimPartsAmount: parts,
        claimMileageAmount: mileage,
        claimTravelAmount: travel,
        claimTaxAmount: tax,
        claimFreightShippingAmount: freight,
        claimFoodLossAmount: foodLoss,
        claimReimbursementAmount: claimPayment,
        claimReimbursementMethod: claimReimbursementMethod,
        paypalAccountNumber: email,
        paymentReferenceNumber: claimReimbursementMethod + ' Reimbursement',
      },
    },
  })
}

export const processBuyout = async (
  address,
  buyoutAmount,
  city,
  correctiveActionDescription,
  claimReimbursementMethod,
  contractNumber,
  countryCode,
  dealerPricingGroupNumber,
  email,
  externalContractNumber,
  failureCode,
  firstName,
  hasSubclaimFlg,
  lastName,
  phone,
  postalCode,
  repairDt,
  serviceOrderNumber,
  serviceOrderNumberSub,
  state,
) => {
  return await axios(baseURL + 'standard/approvedclaims/payouts', {
    method: 'POST',

    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        paymentNoticeName: dealerPricingGroupNumber,
        serviceOrderNumber: hasSubclaimFlg ? serviceOrderNumberSub : serviceOrderNumber,
        sequenceNumber: 1,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email: email,
        address1: address,
        address2: '',
        city,
        state,
        zipCode: postalCode,
        country: countryCode,
        mobileNumber: phone?.replace(/\D/g, '').replace('+', ''),
      },
      claimInfo: {
        claimCashDisbursementReason: 'Buyout',
        claimStoryReason: failureCode,
        claimCorrectiveAction: 'Cash-Out',
        claimCorrectionDescription: correctiveActionDescription,
        claimDateConsumerFiled: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimFailureCauseType: 'Normal Wear And Tear',
        claimTechnicianName: 'Customer Dispatch',
        claimDateRepaired: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimOutcome: 'Other',
        claimDateAdjudicated: moment.utc().format('YYYY-MM-DD'),
        claimStatusApproveReject: 'Approve',
        claimAmountTotal: buyoutAmount,
        claimLaborAmount: 0.0,
        claimPartsAmount: 0.0,
        claimMileageAmount: 0.0,
        claimTravelAmount: 0.0,
        claimTaxAmount: 0.0,
        claimFreightShippingAmount: 0.0,
        claimFoodLossAmount: 0.0,
        claimReimbursementAmount: buyoutAmount,
        claimReimbursementMethod: claimReimbursementMethod,
        paypalAccountNumber: email,
        paymentReferenceNumber: claimReimbursementMethod + ' Reimbursement',
      },
    },
  })
}

export const getDispatchMessageList = async () => {
  const response = await axios(baseURL + 'standard/storedprocedures/getdispatchmessagelist', {
    method: 'GET',
  })

  return response
}

export const getContractSearch = async () => {
  const response = await axios('https://es.opwforge.com/phx/_search', {
    auth: {
      username: 'kustomer',
      password: 'TA3#2r88k52u',
    },
    method: 'GET',

    data: {
      query: {
        term: {
          'Contract Number.keyword': 'ON11397115',
        },
      },
    },
  })

  return response
}

export const getCountryList = async () => {
  return await axios(`${process.env.REACT_APP_API_SDPROVIDER_URL}getCountryList`, {
    method: 'GET',
  })
}
export const getStateDetail = async (countryId) => {
  return await axios(
    `${process.env.REACT_APP_API_SDPROVIDER_URL}/getStateDetail?countryId=${countryId}`,
    {
      method: 'GET',
    },
  )
}
export const getReasonList = async () => {
  return await axios(
    `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=BOTH&reasonType=REASSIGN`,
    {
      method: 'GET',
    },
  )
}
export const getCancelReasonsList = async () => {
  return await axios(
    `${
      process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL
    }getReasonList?providerType=${'BOTH'}&reasonType=CANCEL`,
    {
      method: 'GET',
    },
  )
}
export const getRescheduleReasonList = async (servicerData) => {
  return await axios(
    `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=${
      servicerData?.providerType === 'A&E' ? 'A%26E' : 'OPW'
    }&reasonType=RESCHEDULE`,
    {
      method: 'GET',
    },
  )
}
export const cancelBookedAppointment = async (reqBody) => {
  return await axios(baseURL + 'standard/cancel/appointment', {
    method: 'POST',

    data: {
      ...reqBody,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    },
  })
}
export const reassignAppointment = async (reqBody) => {
  return await axios(baseURL + 'standard/reassign/appointment', {
    method: 'POST',

    data: {
      ...reqBody,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    },
  })
}
export const getWeeklyAvailabilityByServicer = async (reqBody) => {
  return await axios(
    process.env.REACT_APP_API_SDPROVIDER_URL + 'v2/getWeeklyAvailabilityByServicer',
    {
      method: 'POST',

      data: {
        ...reqBody,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneMinuteDifference: new Date().getTimezoneOffset(),
      },
    },
  )
}
export const verifyContractDetails = async (reqBody) => {
  return await axios(baseURL + 'verifyContractDetails', {
    method: 'POST',

    data: {
      ...reqBody,
    },
  })
}
export const userLogin = async (reqBody) => {
  return await axios(`${process.env.REACT_APP_LOGIN_URL}users/login`, {
    method: 'POST',

    data: {
      ...reqBody,
    },
  })
}
export const getOrderDetails = async (servicerData) => {
  return await axios(
    `${process.env.REACT_APP_API_DEVELOPMENT_URL}getOrderDetails?serviceOrderId=${
      servicerData?.serviceOrderNumber || servicerData?.serviceOrderId
    }`,
    {
      method: 'GET',
    },
  )
}
export const getServicerDetail = async (servicerData, existingAppointment) => {
  return await axios(
    `${process.env.REACT_APP_API_SDPROVIDER_URL}getServicerDetail?servicerAccountId=${servicerData?.servicerAccountId}&industryId=${existingAppointment.industryId}`,

    {
      method: 'GET',
    },
  )
}
export const getSoftAvailabilityServicerDetail = async (subItems) => {
  return await axios(
    `${process.env.REACT_APP_API_SDPROVIDER_URL}getSoftAvailabilityServicerDetail?servicerId=${subItems?.servicerId}`,

    {
      method: 'GET',
    },
  )
}
export const getReasonListByProviderType = async () => {
  return await axios(
    `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=OPW&reasonType=SORTORDER`,

    {
      method: 'GET',
    },
  )
}
export const getDropdownData = async (template) => {
  return await axios(
    `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}${'get' + template}`,
    {
      method: 'GET',
    },
  )
}
