import React, { Component } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Typography,
} from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos, Close } from '@mui/icons-material'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { bookAppointment, getServiceOrders } from '../api/serviceavenger'
import Afternoon from '../img/Afternoon.svg'
import Evening from '../img/Evening.svg'
import firmAvailability from '../img/appointmentfirm.svg'
import Morning from '../img/Morning.svg'
import NotAfterNoon from '../img/Afternoon-not-available.svg'
import NotEvening from '../img/Evening-not-available.svg'
import NotMorning from '../img/Morning-not-available.svg'
import SoftAvailability from '../img/appointment (1) (2) (1).svg'

export default class AssignmentDialog extends Component {
  state = {
    firstWeek: [],
    secondWeek: [],
    showFirstWeek: true,
    showSecondWeek: false,
    isOpenCreateEmptyDialog: false,
    isLoading: false,
    isErrorOccur: '',
    warrantyTypeId: '',
  }
  componentDidMount() {
    const newArr = this.props.data.slice(0, 7)
    const secondNewArr = this.props.data.slice(7, 14)
    this.setState({ firstWeek: newArr, secondWeek: secondNewArr })
  }

  resetStatesOnCloseDialog = () => {
    this.setState({
      isOpenCreateEmptyDialog: false,
      isLoading: false,
      isErrorOccur: '',
    })
  }

  handleForward = () => {
    this.setState({ showFirstWeek: false })
  }

  handleBackward = () => {
    this.setState({ showFirstWeek: true })
  }
  handleOpenCreateEmptyOrderDialog = () => {
    this.setState({ isOpenCreateEmptyDialog: true })
  }
  handleCloseCreateEmptyOrderDialog = () => {
    this.resetStatesOnCloseDialog()
  }
  handleCreateEmptyServiceOrder = async () => {
    this.setState({ isLoading: true })
    const {
      firstName = '',
      lastName = '',
      address = '',
      address2 = '',
      businessName = '',
      city = '',
      state = '',
      postalCode = '',
      primaryPhone = '',
      email = '',
      countryCode = '',
      customerId = '',
      serviceNotice = '',
      sequenceNumber = '',
      brandCode = '',
      productCode = '',
      catCode = '',
      model = '',
      serial = '',
      installDt = '',
      productTierCode = '',
      productPurchaseDate = '',
      subCategory = '',
      warrantyType = '',
      effectiveDt = '',
      expirationDt = '',
      contractNumber = '',
      externalContractNumber = '',
      warrantyTypeId = '',
      serviceOrderNumber = '',
      failureDt = '',
      serviceType = '',
      serviceElevation = '',
      serviceLocation = '',
      failureType = '',
      functionalityStatus = '',
      symptomCode = '',
      failureNote = '',
      bookingNote = '',
      dealerCustomerId = '',
      procedureCode = '',
      isFnolCalled = '',
      serviceLocationId = '',
      serviceTypeId = '',
      dealerName = '',
    } = this.props

    const reqBody = {
      customer: {
        firstName: firstName,
        lastName: lastName,
        address1: address,
        address2: address2,
        businessName: businessName,
        city: city,
        stateProvince: state,
        zipCode: postalCode,
        cellPhone: primaryPhone,
        otherPhone: '',
        email: email,
        countryCode: countryCode,
        crmKey: customerId,
        repeatCall: serviceNotice === 'Repeat Request' ? 'YES' : 'NO',
        initialAppointmentDate: '',
        initialAppointmentWindow: '',
      },
      product: {
        sequenceNumber: sequenceNumber,
        brandCode: brandCode,
        catCode: catCode,
        productCode: productCode,
        appointmentBrand: '',
        appointmentProduct: '',
        modelNumber: model,
        serialNumber: serial,
        installDate: moment(installDt).format('YYYY-MM-DD'),
        tierCode: productTierCode,
        productPurchaseDate: moment(productPurchaseDate).format('YYYY-MM-DD'),
        productSubCategory: subCategory,
      },
      warranty: {
        warrantyType: warrantyType,
        coverageBeginDate: moment(effectiveDt).format('YYYY-MM-DD'),
        coverageEndDate: moment(expirationDt).format('YYYY-MM-DD'),
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber: externalContractNumber,
        warrantyTypeId: warrantyTypeId,
      },
      service: {
        serviceOrderNumber: serviceOrderNumber,
        externalAppointmentNumber: '',
        serviceCallDate: moment().format('YYYY-MM-DD hh:mm:ss'),
        failureDate: moment(failureDt).format('YYYY-MM-DD'),
        serviceRequestType: serviceType,
        serviceElevation: serviceElevation,
        serviceSite: serviceLocation,
        failureType: failureType,
        functionalityStatus: functionalityStatus,
        symptomCode: symptomCode,
        failureNote: failureNote,
        bookingType: 'forced',
        providerType: 'OPW',
        groupID: '',
        isReschedule: false,
        serviceNotice: serviceNotice,
        relatedTo: serviceNotice === 'Repeat Request' ? '' : null,
        bookingNote: bookingNote,
        dealerId: dealerCustomerId,
        procedureCode: procedureCode ? procedureCode : 'Field Service',
        isFnolCalled: isFnolCalled,
        serviceLocationId: serviceLocationId,
        serviceTypeId: serviceTypeId,
        dealerName: dealerName,
        isForceBooking: 1,
      },
      appointment: {
        offerKey: '',
        appointmentDate: '',
        appointmentTimeSlot: '',
        appointmentDay: '',
        consumerRequestDate: '',
        consumerRequestTime: '',
        specialInstructions: '',
        groupID: '',
        preferredServicerName: 'Preferred Service Provider',
      },
      servicer: {
        servicerID: '',
        servicerAccount: '',
        servicerName: 'NO SERVICERS FOUND',
        servicerPhone: '',
        servicerWebsite: '',
      },
      parts: {
        partDistibutorID: '',
        partDistributorName: '',
        partNumber: '',
        partDescription: '',
        partQuantity: 0,
        partPrice: 0.0,
        shipperCompany: '',
        shipperTracking: '',
      },
      payment: {
        deductible: 0.0,
        poNumber: '',
        poAmount: 0.0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: '',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
      visitFrequency: 'NO',
      forcedBookingFlag: 'YES',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    }
    const toastId = toast.loading('Creating Empty Service Order')
    try {
      const response = await bookAppointment(reqBody)
      toast.dismiss(toastId)

      if (response && response.data.isSuccess) {
        toast.success(response?.data?.message)
        await getServiceOrders(contractNumber)
        this.setState({ isOpenCreateEmptyDialog: false })
        this.props.handleClose()
        this.resetStatesOnCloseDialog()
      } else {
        this.setState({ isOpenCreateEmptyDialog: true, isLoading: true })
      }
    } catch (error) {
      toast.dismiss(toastId)
      this.setState({ isLoading: false, isErrorOccur: error?.message })
      toast.error(error?.message)
    }
  }

  render() {
    const { assignmentDialogOpen, handleClose, openConfirmationDialog, appointmentWorking } =
      this.props
    const isTimeSlotPresent = (availability, str) => {
      return availability && availability.some((slot) => slot.slotName === str)
    }
    const {
      firstWeek,
      secondWeek,
      showFirstWeek,
      isOpenCreateEmptyDialog,
      isLoading,
      isErrorOccur,
    } = this.state
    return (
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={assignmentDialogOpen}
          PaperProps={{
            sx: {
              maxWidth: '100vw',
              width: '100vw',
              height: '100vh',
              borderRadius: '20px',
              paddingRight: '1rem',
              paddingLeft: '1rem',
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '28px',
            }}
            id='customized-dialog-title'
          >
            Assign Servicer <br />
          </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent className='custom-scrollbar' sx={{ overflowY: 'scroll', height: '100%' }}>
            <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }}>
              {showFirstWeek &&
                firstWeek?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={1.7}
                    style={{
                      borderLeft: '.5px dotted #EFEFEF',
                      borderRight: '.5px dotted #EFEFEF',
                      borderBottom: '.5px dotted #EFEFEF',
                    }}
                  >
                    <List
                      className='custom-scrollbar'
                      sx={{
                        overflowY: 'hidden',
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        padding: '0',
                        position: 'relative',
                      }}
                      component='nav'
                      aria-labelledby='nested-list-subheader'
                      subheader={
                        <ListSubheader
                          style={{
                            background: '#F2F4FF',
                            textAlign: 'center',
                            fontSize: '14px',
                            position: 'sticky',
                            top: 0,
                            lineHeight: '1.8rem',
                          }}
                          component='div'
                          id='nested-list-subheader'
                        >
                          {item.day} <br />
                          {item.date}
                          {index === firstWeek.length - 1 && (
                            <ArrowForwardIos
                              style={{
                                fontSize: '12px',
                                position: 'absolute',
                                right: 20,
                                top: 20,
                                cursor: 'pointer',
                              }}
                              onClick={() => this.handleForward()}
                            />
                          )}
                        </ListSubheader>
                      }
                    >
                      {item.availability.map((subItems, subIndex) => (
                        <Grid
                          container
                          xs={12}
                          style={{
                            height: '8rem',
                            marginTop: '2%',
                            width: '100%',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingBottom: '10px',
                            border: '1px solid rgba(234, 234, 234, 1)',
                            alignItems: 'flex-start',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{ padding: '0px' }}
                            style={{ alignItems: 'center' }}
                          >
                            <Grid item xs={3}>
                              {subItems?.serviceProvider === 'A&E' ? (
                                <img
                                  src={firmAvailability}
                                  alt='user-pic'
                                  height='24px'
                                  width='24px'
                                />
                              ) : (
                                <img
                                  src={SoftAvailability}
                                  alt='user-pic'
                                  height='24px'
                                  width='24px'
                                />
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                onClick={() =>
                                  openConfirmationDialog('SoftAvailability', subItems, subIndex)
                                }
                                sx={{
                                  fontSize: '11px',
                                  wordWrap: 'break-word',
                                  color: 'rgba(2, 160, 252, 1)',
                                  cursor: 'pointer',
                                }}
                              >
                                {subItems?.serviceProvider}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} style={{ alignItems: 'top' }}>
                            {subItems?.serviceProvider !== 'A&E' && subItems?.provider !== 'A&E' ? (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '10px' }}>Availability : </span>
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'morning') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Morning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotMorning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'afternoon') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Afternoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotAfterNoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'evening') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Evening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotEvening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '10px' }}>Availability : </span>
                                </Grid>
                                {subItems?.timeSlot?.map((timeData) => (
                                  <Grid item style={{ alignItems: 'top' }}>
                                    <Grid
                                      style={{
                                        border: '1px solid rgba(234, 234, 234, 1)',
                                        borderRadius: '8px',
                                        background: 'rgba(245, 245, 245, 1)',
                                        marginLeft: '5px',
                                        marginBottom: '10px',
                                        padding: '0 3px',
                                        height: '24px',
                                      }}
                                    >
                                      <span style={{ fontSize: '11px' }}>
                                        {' '}
                                        {timeData?.slotName}
                                      </span>
                                    </Grid>
                                  </Grid>
                                ))}
                              </React.Fragment>
                            )}
                          </Grid>
                          {(subItems?.sameDayCutOff || subItems?.nextDayCutOff) && (
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ alignItems: 'top', flexWrap: 'wrap' }}
                            >
                              {subItems?.sameDayCutOff && (
                                <Grid xs={12} item>
                                  <span style={{ fontSize: '10px' }}>
                                    {' '}
                                    Same Day Cut-Off: {subItems.sameDayCutOff}
                                    {subItems.sameDayAmPm}{' '}
                                  </span>
                                </Grid>
                              )}
                              {subItems.nextDayCutOff && (
                                <Grid xs={12} item>
                                  <span style={{ fontSize: '10px' }}>
                                    {' '}
                                    Next Day CutOff: {subItems.nextDayCutOff}
                                    {subItems.nextDayAmPm}
                                  </span>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </List>
                  </Grid>
                ))}
              {!showFirstWeek &&
                secondWeek?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={1.7}
                    style={{
                      borderLeft: '.5px dotted #EFEFEF',
                      borderRight: '.5px dotted #EFEFEF',
                      borderBottom: '.5px dotted #EFEFEF',
                    }}
                  >
                    <List
                      className='custom-scrollbar'
                      sx={{
                        overflowY: 'hidden',
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        padding: '0',
                        position: 'relative',
                      }}
                      component='nav'
                      aria-labelledby='nested-list-subheader'
                      subheader={
                        <ListSubheader
                          style={{
                            background: '#F2F4FF',
                            textAlign: 'center',
                            fontSize: '14px',
                            position: 'sticky',
                            top: 0,
                            lineHeight: '1.8rem',
                          }}
                          component='div'
                          id='nested-list-subheader'
                        >
                          {index === 0 && (
                            <ArrowBackIosNew
                              style={{
                                fontSize: '12px',
                                position: 'absolute',
                                left: 20,
                                top: 20,
                                cursor: 'pointer',
                              }}
                              onClick={() => this.handleBackward()}
                            />
                          )}
                          {item.day} <br />
                          {item.date}
                        </ListSubheader>
                      }
                    >
                      {item.availability.map((subItems, subIndex) => (
                        <Grid
                          container
                          xs={12}
                          style={{
                            height: '8rem',
                            marginTop: '2%',
                            width: '100%',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingBottom: '10px',
                            border: '1px solid rgba(234, 234, 234, 1)',
                            alignItems: 'flex-start',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{ padding: '0px' }}
                            style={{ alignItems: 'center' }}
                          >
                            <Grid item xs={3}>
                              {subItems?.serviceProvider === 'A&E' &&
                              subItems?.provider === 'A&E' ? (
                                <img
                                  src={firmAvailability}
                                  alt='user-pic'
                                  height='24px'
                                  width='24px'
                                />
                              ) : (
                                <img
                                  src={SoftAvailability}
                                  alt='user-pic'
                                  height='24px'
                                  width='24px'
                                />
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                onClick={() =>
                                  openConfirmationDialog('SoftAvailability', subItems, subIndex)
                                }
                                sx={{
                                  fontSize: '11px',
                                  wordWrap: 'break-word',
                                  color: 'rgba(2, 160, 252, 1)',
                                  cursor: 'pointer',
                                }}
                              >
                                {subItems?.serviceProvider}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} style={{ alignItems: 'top' }}>
                            {subItems?.serviceProvider !== 'A&E' && subItems?.provider !== 'A&E' ? (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '10px' }}>Availability : </span>
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'morning') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Morning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotMorning}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'afternoon') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Afternoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotAfterNoon}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item style={{ alignItems: 'top' }}>
                                  {isTimeSlotPresent(subItems?.timeSlot, 'evening') ? (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={Evening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                      <img
                                        src={NotEvening}
                                        alt='user-pic'
                                        height='24px'
                                        width='24px'
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Grid item>
                                  <span style={{ fontSize: '10px' }}>Availability : </span>
                                </Grid>
                                {subItems?.timeBand?.map((timeData) => (
                                  <Grid item style={{ alignItems: 'top' }}>
                                    <Grid
                                      style={{
                                        border: '1px solid rgba(234, 234, 234, 1)',
                                        borderRadius: '8px',
                                        background: 'rgba(245, 245, 245, 1)',
                                        marginLeft: '5px',
                                        marginBottom: '10px',
                                        padding: '0 3px',
                                        height: '24px',
                                      }}
                                    >
                                      <span style={{ fontSize: '11px' }}>
                                        {' '}
                                        {timeData?.slotName}
                                      </span>
                                    </Grid>
                                  </Grid>
                                ))}
                              </React.Fragment>
                            )}
                          </Grid>
                          {(subItems?.sameDayCutOff || subItems?.nextDayCutOff) && (
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ alignItems: 'top', flexWrap: 'wrap' }}
                            >
                              {subItems?.sameDayCutOff && (
                                <Grid xs={12} item>
                                  <span style={{ fontSize: '10px' }}>
                                    {' '}
                                    Same Day Cut-Off: {subItems.sameDayCutOff}
                                    {subItems.sameDayAmPm}{' '}
                                  </span>
                                </Grid>
                              )}
                              {subItems.nextDayCutOff && (
                                <Grid xs={12} item>
                                  <span style={{ fontSize: '10px' }}>
                                    {' '}
                                    Next Day CutOff: {subItems.nextDayCutOff}
                                    {subItems.nextDayAmPm}
                                  </span>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </List>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingBottom: '2rem' }}>
            <Grid container xs={12} style={{ fontSize: '14px', alignItems: 'center' }}>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SoftAvailability} alt='user-pic' height='24px' width='24px' />
                &nbsp;&nbsp; Soft Availability
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={firmAvailability} alt='user-pic' height='24px' width='24px' />
                &nbsp; &nbsp; Firm Availability
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Morning} alt='user-pic' height='24px' width='24px' />
                &nbsp; &nbsp; Morning
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Afternoon} alt='user-pic' height='24px' width='24px' />
                &nbsp; &nbsp; Afternoon
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Evening} alt='user-pic' height='24px' width='24px' />
                &nbsp; &nbsp; Evening
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={this.handleOpenCreateEmptyOrderDialog}>
                  Create Empty Service Order
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
          {appointmentWorking && (
            <Row className='mt-5 ps-3 pe-3'>
              <Col>
                <Spinner animation='border'></Spinner>
                <br />
                <span className='spinner-text'>Booking appointment...</span>
              </Col>
            </Row>
          )}
        </Dialog>
        {isOpenCreateEmptyDialog && (
          <Dialog
            aria-labelledby='customized-dialog-title'
            open={isOpenCreateEmptyDialog}
            PaperProps={{ sx: { borderRadius: '15px', minWidth: '35vw', minHeight: '35vh' } }}
          >
            <DialogContent className='d-flex flex-column justify-content-center align-items-center p-3'>
              <Grid className='d-flex justify-content-center mb-3'>
                Are you sure you want to <br />
                create an open ticket?
              </Grid>
              <Grid className='d-flex justify-content-center gap-3 mt-3'>
                {!isLoading ? (
                  <React.Fragment>
                    <Button onClick={this.handleCreateEmptyServiceOrder}>Yes</Button>
                    <Button onClick={this.handleCloseCreateEmptyOrderDialog}>Close</Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    Creating Empty Service Order...
                  </React.Fragment>
                )}
              </Grid>
              {isErrorOccur?.length > 0 && (
                <span className='alert alert-danger mt-3' role='alert'>
                  {`Error Creating Empty Service Order... ${isErrorOccur}`}
                </span>
              )}
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}
