import * as React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-hot-toast'
import Icon from '../../img/delivery-box (1) 1.svg'
import { getReasonList, reassignAppointment } from '../../api/serviceavenger'
import './../App.css'

export default function ConfirmationDialog({
  reassignIndex,
  finalBookingTimeSlot,
  reassignReason,
  setReassignReason,
  dropDownAvailability,
  setDropDownAvailability,
  setFinalBookingTimeSlot,
  appointmentDay,
  appointmentDate,
  setFailureId,
  reassignNote,
  setReassignOpen,
  serviceOrder,
  consumerDetails,
  productDetails,
  bookingContent,
  handleConfirmDialog,
  setHandleConfirmDialog,
  setFinalConfirmDialog,
}) {
  const [sortedTimeBand, setSortedTimeBand] = React.useState([])

  React.useEffect(() => {
    if (bookingContent && Array.isArray(bookingContent?.timeSlot)) {
      let arr = bookingContent?.timeSlot
      let newArr = arr?.map((item) => item.slotName)
      newArr.sort((a, b) => {
        const order = { morning: 1, afternoon: 2, evening: 3 }
        return order[a] - order[b]
      })
      setSortedTimeBand(newArr)
    }
  }, [bookingContent])

  const [reassignList, setReassignList] = React.useState([])

  const getReassignList = async () => {
    try {
      const response = await getReasonList()
      if (response?.data?.isSuccess) {
        setReassignList(response.data.items)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  React.useEffect(() => {
    getReassignList()
    setFinalBookingTimeSlot('')
    setReassignReason('')
  }, [setFinalBookingTimeSlot, setReassignReason])

  const handleClose = () => {
    setFinalBookingTimeSlot('')
    setDropDownAvailability(false)
    setHandleConfirmDialog(false)
  }

  const handleOpenFinalConfirm = async () => {
    let newcOverageBegindate =
      serviceOrder && serviceOrder?.coverageStartDate
        ? new Date(serviceOrder?.coverageStartDate).toISOString().split('T')[0]
        : ''
    let newcoverageEndDate =
      serviceOrder && serviceOrder?.coverageEndDate
        ? new Date(serviceOrder?.coverageEndDate).toISOString().split('T')[0]
        : ''
    var newserviceCallDate = serviceOrder && new Date().toISOString().slice(0, 10)

    let newfailureDate =
      serviceOrder && new Date(serviceOrder?.failureDate).toISOString().split('T')[0]
    let newappointmentDate = appointmentDate ? appointmentDate.split('/').reverse().join('-') : ''
    let consumerRequestDate =
      serviceOrder && serviceOrder?.consumerRequestDate
        ? new Date(serviceOrder?.consumerRequestDate).toISOString().split('T')[0]
        : ''

    let newinstallDate = new Date(productDetails?.installDate).toISOString().split('T')[0]

    setHandleConfirmDialog(false)
    setFinalConfirmDialog(false)
    setReassignOpen(false)

    const reqBody = {
      customer: {
        firstName: consumerDetails?.firstName,
        lastName: consumerDetails?.lastName,
        address1: consumerDetails?.address1,
        address2: consumerDetails?.address2,
        businessName: consumerDetails?.businessName,
        city: consumerDetails?.city,
        stateProvince: consumerDetails?.state,
        zipCode: consumerDetails?.postalCode,
        cellPhone: consumerDetails?.phone,
        otherPhone: consumerDetails?.secondaryPhone,
        email: consumerDetails?.email,
        countryCode: consumerDetails?.countryCode,
        crmKey: serviceOrder?.crmKey,
      },
      product: {
        sequenceNumber: productDetails?.productSequenceNumber,
        brandCode: productDetails?.brandName,
        catCode: productDetails?.industryCode,
        productCode: productDetails?.productCode,
        appointmentBrand: productDetails?.industryName,
        appointmentProduct: productDetails?.appointmentProduct,
        modelNumber: productDetails?.modelNumber,
        serialNumber: productDetails?.serialNumber,
        installDate: newinstallDate,
        tierCode: serviceOrder?.tierCode,
      },
      warranty: {
        warrantyType: serviceOrder?.warrantyType,
        coverageBeginDate: newcOverageBegindate,
        coverageEndDate: newcoverageEndDate,
        serviceAvengerContractNumber: serviceOrder?.serviceContractNumber,
        externalContractNumber: serviceOrder?.externalContractNumber,
        warrantyTypeId: serviceOrder?.warrantyTypeId,
      },
      service: {
        serviceOrderNumber: serviceOrder?.serviceOrderId,
        externalAppointmentNumber: '',
        serviceCallDate: newserviceCallDate,
        failureDate: newfailureDate,
        serviceRequestType: serviceOrder?.serviceType,
        serviceElevation: serviceOrder?.serviceRequestElevation,
        serviceSite: serviceOrder?.serviceSite,
        failureType: serviceOrder?.failureType,
        functionalityStatus: serviceOrder?.functionalityStatus,
        symptomCode: serviceOrder?.symptomCode,
        failureNote: serviceOrder?.failureNote,
        bookingType: bookingContent?.svcrType === `WEB` ? 'soft' : 'firm', //serviceOrder.bookingType,
        relatedTo: serviceOrder?.serviceOrderId,
        providerType: bookingContent?.svcrType === `WEB` ? 'OPW' : 'A&E', //serviceOrder?.providerType,
        groupID: serviceOrder?.groupID,
        bookingNote: reassignNote,
        reassignReasonCode: reassignReason,
        dealerId: serviceOrder?.dealerID,
        procedureCode: serviceOrder?.procedureCode,
        serviceLocationId: serviceOrder?.serviceLocationId,
        serviceTypeId: serviceOrder?.serviceTypeId,
        dealerName: serviceOrder?.dealerName,
      },

      appointment: {
        offerKey: bookingContent?.offerID,
        appointmentDate: newappointmentDate,
        appointmentTimeSlot: finalBookingTimeSlot,
        consumerRequestDate: consumerRequestDate,
        consumerRequestTime: serviceOrder?.appointmentTimeSlot,
        specialInstructions: serviceOrder?.specialInstructions,
        providerId: bookingContent?.timeSlot[0]?.providerId,
        firstAvailableDate: bookingContent?.firstAvailableDate,
        capacityArea: bookingContent?.capacityArea,
        workAreaCode: bookingContent?.workAreaCode,
      },
      servicer: {
        specialInstructions: serviceOrder?.specialInstructions,
        servicerID: bookingContent?.servicerId,
        servicerAccount: bookingContent?.servicerAccount,
        servicerName: bookingContent?.serviceProvider,
        servicerPhone: bookingContent?.svcrPhone,
        servicerWebsite: '',
        serviceUnitNumber: bookingContent?.serviceUnitNumber || '',
        serviceNotice: serviceOrder?.notice,
      },
      parts: {
        partDistibutorID: '',
        partDistributorName: '',
        partNumber: '',
        partDescription: '',
        partQuantity: 0,
        partPrice: 0,
        shipperCompany: '',
        shipperTracking: '',
      },
      payment: {
        deductible: 0,
        poNumber: '',
        poAmount: 0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: '',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
      visitFrequency: 'NO',
    }

    const toastId = toast.loading('Reassign is in Progress', {
      autoClose: false,
    })
    try {
      const response = await reassignAppointment(reqBody)
      toast.dismiss(toastId)
      if (response.data.isSuccess) {
        toast.success('Reassigned Successfully')
        setFailureId(response?.data?.data?.serviceOrderNumber?.serviceOrderNumber)
        setFinalConfirmDialog(true)
      }
    } catch (error) {
      toast.dismiss(toastId)
      toast.error(error?.message)
    }

    setHandleConfirmDialog(false)
  }

  const handleAvailabilityRadio = (event) => {
    setDropDownAvailability(true)
    setFinalBookingTimeSlot(event.target.value)
  }

  const changeDateFormat = () => {
    const [year, month, day] = appointmentDate.split('-')

    // Convert the month number to its corresponding full month name
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const fullMonthName = monthNames[parseInt(month, 10) - 1]

    const formattedDate = `${fullMonthName} ${parseInt(day, 10)}, ${year}`
    return formattedDate
  }
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={handleConfirmDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '29rem',
            height: '50rem',
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: 'center' }}
          sx={{ m: 3, p: 2, mb: 0, pb: 0 }}
          id='customized-dialog-title'
        >
          <img
            src={Icon}
            alt='user-pic'
            height='45px'
            width='45px'
            style={{
              background: 'rgba(235, 248, 255, 1)',
              padding: '10px',
              borderRadius: '48%',
            }}
          />
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            height: '25px',
            width: '25px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ textAlign: 'center' }}>
          <Grid>
            <Grid item xs={12} style={{ justifyContent: 'center', marginTop: '1.8rem' }}>
              <Grid>
                <span style={{ fontSize: '15px' }}>
                  Please select availability <br /> for {appointmentDay},{' '}
                  {changeDateFormat(appointmentDate)}.
                </span>
              </Grid>

              <Grid className='confirmation-dialog-ParentGrid '>
                <Paper elevation={0} style={{ borderRadius: '18px', overflow: 'hidden' }}>
                  <Grid className='confirmation-dialog-grid '>
                    {bookingContent?.serviceProvider}
                  </Grid>
                  <Grid
                    style={{
                      background: 'rgba(242, 244, 255, 1)',
                      padding: '1rem',
                      textAlign: 'left',
                    }}
                  >
                    <span>
                      <b>Servicer Account ID : </b>{' '}
                      <span style={{ color: '#02A0FC' }}>{bookingContent?.servicerAccount}</span>
                    </span>
                    <span>
                      <b>Dispatch Contact : </b>{' '}
                      <span style={{ color: '#02A0FC' }}>
                        {bookingContent?.dispatchMethodPhone}{' '}
                        {bookingContent?.dispatchMethodEmail && (
                          <React.Fragment>
                            <br />
                            {bookingContent?.dispatchMethodEmail}
                          </React.Fragment>
                        )}
                      </span>
                    </span>
                    <br />
                    <span>
                      <b> Dispatch Address :</b> {bookingContent?.dispatchAddress}
                    </span>
                    <br />
                    <span>
                      <b>Notes : </b> <span>{bookingContent?.notes}</span>
                    </span>
                    <br />
                  </Grid>
                </Paper>
              </Grid>

              <Grid container xs={12} style={{ flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <br />

                <Grid item xs={3} sx={{ marginTop: '8px' }}>
                  <span>
                    <b> Availability : </b>
                  </span>
                </Grid>
                <Grid container xs={8}>
                  <RadioGroup
                    name='options'
                    value={finalBookingTimeSlot}
                    onChange={handleAvailabilityRadio}
                    row
                  >
                    <Grid container xs={12}>
                      {bookingContent.svcrType === 'WEB' ? (
                        <React.Fragment>
                          {Array.isArray(bookingContent?.timeSlot) &&
                            sortedTimeBand.map((time) => (
                              <Grid
                                item
                                sm={6}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                                sx={{ margin: 0 }}
                              >
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  value={time}
                                  control={<Radio />}
                                  label={
                                    <span style={{ fontSize: '12px', margin: 0 }}>
                                      {time.charAt(0).toUpperCase() + time.slice(1)}
                                    </span>
                                  }
                                />
                              </Grid>
                            ))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {Array.isArray(bookingContent?.timeSlot) &&
                            bookingContent?.timeSlot?.map((item) => (
                              <Grid item xs={7} className='d-flex flex-start m-0'>
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  value={item.slotName}
                                  control={<Radio />}
                                  label={<span>{item.slotName}</span>}
                                />
                              </Grid>
                            ))}
                        </React.Fragment>
                      )}
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              {reassignIndex !== 0 && dropDownAvailability && (
                <React.Fragment>
                  <Grid style={{ fontSize: '12px' }}>
                    <span>Please select a reason for choosing this servicer</span>
                  </Grid>
                  <Grid style={{ width: '90%', margin: 'auto' }}>
                    <FormControl className='mt-2' fullWidth size='small'>
                      <Select
                        value={reassignReason ? reassignReason : ''}
                        onChange={(event) => {
                          const reason = event.target.value
                          setReassignReason(reason)
                        }}
                      >
                        {reassignList?.map((value) => (
                          <MenuItem key={value.reasonCode} value={value.reasonCode}>
                            {value.reasonName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              <Grid className='mt-2'>
                <Button
                  variant='contained'
                  disabled={
                    (reassignIndex !== 0 && dropDownAvailability && reassignReason === '') ||
                    finalBookingTimeSlot === ''
                      ? true
                      : false
                  }
                  onClick={() => handleOpenFinalConfirm()}
                  className='ml-2'
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
